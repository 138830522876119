h1 {
  @apply font-bold text-3xl md:text-5xl lg:text-[3.5rem] lg:leading-[1.28571429];
}

h2 {
  @apply font-bold text-2xl md:text-3xl lg:text-5xl lg:leading-[1.33333333];
}

h3 {
  @apply font-bold text-lg md:text-xl lg:text-3xl lg:leading-[1.5];
}
