.button {
  @apply inline-flex 
        items-center
        px-6 
        h-12 
        rounded-lg 
        text-accent 
        bg-contrast-accent 
        text-center 
        font-medium 
        outline 
        outline-0 
        outline-offset-2 
        outline-contrast-accent 
        hover:no-underline 
        hover:outline-1
        justify-center 
        gap-2
        transition-all;

  &:disabled {
    @apply opacity-50 
            cursor-not-allowed 
            pointer-events-none
            bg-contrast-secondary;
  }

  &[processing] {
    @apply opacity-50
            cursor-not-allowed 
            pointer-events-none;
  }

  .spinner {
    @apply hidden;
  }

  &[processing] .spinner {
    @apply block;
  }
}

.button-primary {
  @apply bg-primary text-contrast-primary outline-primary;
}

.button-sm {
  @apply h-10 px-4;
}

.button-outline {
  @apply bg-transparent 
        border 
        border-line-secondary 
        text-primary 
        hover:border-line-primary 
        hover:outline-0;
}
